import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";
import templos, { sectors } from "../content/data/templos";
import Heading from "../components/Heading";
import { rhythm } from "../utils/typography";
import SideBar from "../components/SideBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
`;

const TempleContainer = styled.div`
  padding-top: ${rhythm(0.5)};
  padding-bottom: ${rhythm(1)};
`;

const Title = styled.div`
  color: #000;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.1;
`;

const Pastor = styled.a`
  color: #929292;
`;

const Addr = styled.div`
  margin-top: 2px;
  display: flex;
  font-weight: bold;
  font-size: 14px;
`;

const Sub = styled.div`
  display: flex;
  font-size: 14px;
`;

export default class NuestrosTemplosPage extends Component {
  render() {
    return (
      <Layout>
        <Seo title="Nuestros Templos" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Content>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  <Heading>{sectors.catedral}</Heading>
                  {templos
                    .filter(t => {
                      return t.sector === sectors.catedral;
                    })
                    .map(this.renderTemple)}
                  <Heading>{sectors.rm}</Heading>
                  {templos
                    .filter(t => {
                      return t.sector === sectors.rm;
                    })
                    .map(this.renderTemple)}
                  <Heading>{sectors.v}</Heading>
                  {templos
                    .filter(t => {
                      return t.sector === sectors.v;
                    })
                    .map(this.renderTemple)}
                  <Heading>{sectors.sur}</Heading>
                  {templos
                    .filter(t => {
                      return t.sector === sectors.sur;
                    })
                    .map(this.renderTemple)}
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} directions={false} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }

  renderTemple({ name, pastor, address, url }) {
    const hasAddress = !!(address && address.line1);
    const hasMap = !!url;
    return (
      <TempleContainer>
        <Grid container spacing={10}>
          <Grid item xs={12} md={hasMap ? 8 : 12}>
            <Title>{name}</Title>
            {pastor && <Pastor>{pastor}</Pastor>}
            {hasAddress && <Addr>{address.line1}</Addr>}
            {hasAddress && address.line2 && <Sub>{address.line2}</Sub>}
            {hasAddress && address.line3 && <Sub>{address.line3}</Sub>}
          </Grid>
          {hasMap && (
            <Grid item xs={12} md={4}>
              <iframe
                title={name}
                width="100%"
                height="200px"
                style={{ border: 0 }}
                src={url}
                allowFullScreen
              />
            </Grid>
          )}
        </Grid>
      </TempleContainer>
    );
  }
}
