export const sectors = {
  catedral: "Catedral Evangélica de Conchalí",
  rm: "Templos Filiales RM",
  v: "Templo Filiales V Región",
  sur: "Iglesias Filiales Zona Sur"
};

export default [
  templo(
    sectors.catedral,
    "Templo Central",
    "Obispo Presidente Rev. Daniel Rodriguez Vergara",
    address("Av. Pedro Fontova 4957", "Conchalí"),
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3331.6019144422203!2d-70.6748102!3d-33.3814575!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c6f451843fdd%3A0x201e7a2004fecc04!2sCatedral+Evangelica+Conchal%C3%AD!5e0!3m2!1sen!2scl!4v1564852005865!5m2!1sen!2scl"
  ),
  templo(
    sectors.catedral,
    "Clase El Mirador",
    undefined,
    address("El Mirador 5757", "El Cortijo"),
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d832.9603462446757!2d-70.689775!3d-33.3752068!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c6e6383c387f%3A0xaa9844c8ce760a09!2sEl+Mirador+5757%2C+Conchal%C3%AD%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1sen!2scl!4v1564852192742!5m2!1sen!2scl"
  ),
  templo(
    sectors.catedral,
    "Clase El Palmar",
    undefined,
    address("Av. La Palmilla 3711", "Conchalí"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.1272627337516!2d-70.66994578480221!3d-33.39384388079012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c662c77ed63d%3A0x80e87fed067f375!2sLa+Palmilla+3711%2C+Conchal%C3%AD%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1sen!2scl!4v1564852226019!5m2!1sen!2scl"
  ),
  templo(
    sectors.catedral,
    "Clase Quilicura",
    undefined,
    address("Lo Marcoleta 0378", "Quilicura"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3332.4047724888155!2d-70.74826488480298!3d-33.3604970807998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c0e931176a1d%3A0x3a33ab111b7befe!2sLo+Marcoleta+378%2C+Quilicura%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1sen!2scl!4v1564852258746!5m2!1sen!2scl"
  ),
  templo(
    sectors.catedral,
    "Clase Jose Garrido",
    undefined,
    address("Gral Gambino 4268", "Conchalí"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.6375277577763!2d-70.66313298480244!3d-33.38052798079405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c65daeb0dd33%3A0x654eb8ade83526b2!2sGral+Gambino+4268%2C+Conchal%C3%AD%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852290412!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.catedral,
    "Clase Juanita Aguirre",
    undefined,
    address("Pasaje Boston 5738", "Conchalí"),
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3331.8981024290424!2d-70.6795545!3d-33.3737262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c6fac1375f49%3A0xa1793440a569f705!2sBoston+5738%2C+Conchal%C3%AD%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852340577!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.catedral,
    "Clase Teniente Yayar",
    undefined,
    address("Teniente Yavar 1621", "Conchalí"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.2180597574716!2d-70.67400288480212!3d-33.39147478079095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c689a3cd36b9%3A0xb36888e5a0a3d35d!2sTeniente+Y%C3%A1var+1621%2C+Conchal%C3%AD%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852400297!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.catedral,
    "Clase Las Condes",
    undefined,
    address("Las Verbenas 8111", "Las Condes"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.957825647995!2d-70.55464628480199!3d-33.39826448078893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cec7dab0bce9%3A0x126e05e33cb143cb!2sLas+Verbenas+8111%2C+Las+Condes%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852436840!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.rm,
    "IRMP La Palma",
    "Pastor Samuel Valdés Campos",
    address("2 Oriente 1105", "Santiago, Estación Central"),
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3328.451373898955!2d-70.6908977!3d-33.4635973!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c4ed70422c53%3A0xba1d34b0812eb7fa!2s2+Ote.+1105%2C+Santiago%2C+Estaci%C3%B3n+Central%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852496183!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.rm,
    "IRMP Pudahuel",
    "Pastor Oscar Sánchez Daza",
    address("Rolando Petersen 1401", "Pudahuel"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.708164420367!2d-70.7523173848012!3d-33.43085208077961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c3c5228659c9%3A0x794887bd3e8c86fe!2sRolando+Petersen+1401%2C+Pudahuel%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852552859!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.rm,
    "IRMP Peñalolén",
    "Pastor Carlos Paredes Olguín",
    address("Valle Hermoso 1470", "Peñalolén"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.054267365832!2d-70.53490408479995!3d-33.47393788076727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662d1fc21dfb8d5%3A0x4555790e46aa86d!2sValle+Hermoso+1470%2C+Penalolen%2C+Pe%C3%B1alol%C3%A9n%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852591687!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.rm,
    "IRMP Maipú",
    "Pastor Pablo Flores Tirillo",
    address("Balmaceda 2129", "Maipú"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.6603955575915!2d-70.78472558479854!3d-33.536214180749475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662dd141e6fc3eb%3A0x8ec58d611fa2f00f!2sPdte+Jos%C3%A9+Manuel+Balmaceda+2129%2C+Maip%C3%BA%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852625854!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.rm,
    "IRMP Maipú",
    "Pastor Washington Guarda Jaramillo",
    address("Olimpo 2466", "Maipú"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.781108886008!2d-70.77785428479915!3d-33.5070716807578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c2d2854756ef%3A0xc5e23253755664ad!2sEl+Olimpo+2466%2C+Maip%C3%BA%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852895373!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.rm,
    "IRMP San Ramón",
    "Pastor Josué Navarrete Rojas",
    address("Ignacio Serrano 9294", "San Ramón"),
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3325.2912604643248!2d-70.6435666!3d-33.5458081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662da6981c83b67%3A0xaf81b261009864e0!2sIgnacio+Serrano+9294%2C+San+Ram%C3%B3n%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses-419!2scl!4v1564852924182!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.v,
    "IRMP IRMP Concón",
    "Pastor Jose Varos Rojas",
    address("Santa Margarita 1310", "Concón"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.0692496648785!2d-71.51224518481429!3d-32.92276858092824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9689c331000939f9%3A0xa01092d68edc70e6!2sSta+Margarita+1310%2C+Conc%C3%B3n%2C+Regi%C3%B3n+de+Valpara%C3%ADso!5e0!3m2!1ses-419!2scl!4v1564852962755!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.sur,
    "IRMP Llanquihue",
    "Pastor Heriberto Hernandez Vargas",
    address("Gabriela Mistral 106", "Llanquihue (Pob. Convi.)")
  ),
  templo(
    sectors.sur,
    "IRMP Llanquihue",
    "Pastor Rudy Andrade Aguilar",
    address("Pob. Los Volcanes M29", "Casa 14")
  ),
  templo(
    sectors.sur,
    "IRMP Castro",
    "Pastor Heriberto Gonzalez Gonzalez",
    address("Calle Inés de Basan 1046", "Castro (Pob. Alonso de Ercilla)"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2943.313329839676!2d-73.78183328454045!3d-42.46362427918013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9622176ef3a6354f%3A0x9e9ac93462dc1c9f!2sIn%C3%A9s+de+Baz%C3%A1n+1046%2C+Castro%2C+Regi%C3%B3n+de+los+Lagos!5e0!3m2!1ses-419!2scl!4v1564852995237!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.sur,
    "IRMP Futrono",
    "Pastor Jose Delgado Sanhueza",
    address("Balmaceda S/N Camino llifen", "Futrono")
  ),
  templo(
    sectors.sur,
    "IRMP Puerto Montt",
    "Pastor Juan Marcos LeviNery Ruiz",
    address("Teresita de Los Andes 28", "Puerto Montt")
  ),
  templo(
    sectors.sur,
    "IRMP Carahue",
    "Pastor Arturo Lincopin Maril",
    address("Villa 11 De Septiembre 1302", "(Esquina Carol Urzua)")
  ),
  templo(
    sectors.sur,
    "IRMP Longaví",
    "Pastor Daniel Alegría Pichuncheo",
    address("La Sexta S/N", "Casa 777")
  ),
  templo(
    sectors.sur,
    "IRMP Isla Negra",
    "Pastor Juan Carlos Adasme Donoso",
    address("Los Cipreses sitio 40", "Isla Negra")
  ),
  templo(
    sectors.sur,
    "IRMP Molina",
    "Pastor Raúl Yáñez Mundaca",
    address("Pastor Garrido 2425"),
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3263.849771319547!2d-71.28291198475674!3d-35.11046198033132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966454a204dc2ee7%3A0x408b93842e192424!2sAv+Pastor+Armando+Garrido+2425%2C+Molina%2C+Regi%C3%B3n+del+Maule!5e0!3m2!1ses-419!2scl!4v1564853056705!5m2!1ses-419!2scl"
  ),
  templo(
    sectors.sur,
    "IRMP Puerto Varas",
    "Pastor Eduardo Venegas Venegas",
    address("Calle Colon C/N Nicanor Garcia", "(Sede Social Puerto Varas)")
  ),
  templo(
    sectors.sur,
    "IRMP Santa Cruz",
    "Pastor Gabriel Hevia Gonzalez",
    address("Rincón Lo Moreno", "Parcela 26")
  ),
  templo(
    sectors.sur,
    "IRMP Cañete",
    "Pastor Jose Lincopin Maril",
    address("Calle Baldomero Lillo Casa 5", "(Pob. Gajardo Sur)", "Cañete")
  )
];

function templo(sector, name, pastor, address, url) {
  return {
    sector,
    name,
    pastor,
    address,
    url
  };
}

function address(line1, line2, line3) {
  return {
    line1,
    line2,
    line3
  };
}
